import React from 'react'
import Cookies from 'js-cookie'
import Config from '@/settings'


const TokenKey = Config.TokenKey
const UserKey = Config.UserKey
const LoginKey = Config.LoginKey
const IsAdminKey = Config.IsAdminKey
const AcitveKey = Config.AcitveKey
const EXPIRESTIME = 12 * 30 * 24 * 3600 * 1000
export function getToken () {
  let token = ''
  if(token == ''){
    token = Cookies.get(TokenKey)
  }
  let Token = token
  return Token
}

export function getLogin() {
  return Cookies.get(LoginKey)
}

export function getIsAdmin() {
  return Cookies.get(IsAdminKey)
}

export function getActive() {
  return Cookies.get(UserKey)
}

export function setToken(token) {
  Cookies.set(TokenKey, token, {expires: EXPIRESTIME})
  return
}

export function setLogin(login){
  Cookies.set(LoginKey, login, {expires: EXPIRESTIME})
  return
}

export function setIsAdmin(isAdmin){
  Cookies.set(IsAdminKey, isAdmin, {expires: EXPIRESTIME})
  return
}

export function setActive(active){
  Cookies.set(AcitveKey, active, {expires: EXPIRESTIME})
  return
}

export function removeToken(){
  Cookies.remove(TokenKey)
  Cookies.remove(UserKey)
  Cookies.remove(IsAdminKey)
  Cookies.remove(AcitveKey)
  localStorage.clear()
  sessionStorage.clear()
  return
}
