// 该模块是用于定义action对象中type类型的常量值，目的只有一个:便于管理的同时防止程序员单词写错

// 菜单默认展开opkenKeys
export const OPENKEYS = 'openKeys';
export const SELECTEDKEYS = 'selectedKeys';

// 面包屑
export const BREADCRUMB = 'breadcrumb'

// 选项卡
export const TABSVIEW = 'tabsView'