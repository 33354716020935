import {lazy} from 'react'
let router = [
    {
        path: "/login",
        component: lazy(()=>import('@/views/Login')),
        redirect: true,// 默认打开登录页
        exact: true //是否为严格匹配
    },
    {
        path: '/forget_password',
        component: lazy(()=>import('@/pages/User/forget')),
        redirect: true,
        exact: false
    },
    {
        path: '/',
        component: lazy(()=>import('@/views/MyView')),
        exact: false,
        routes: [  /** 嵌套路由 */
            {
                path: '/home',
                component: lazy(()=>import('@/pages/Home')),
                exact: false
            },
            {
                path: '/calculation_plans',
                component: lazy(()=>import('@/pages/CalculationPlan')),
                exact: false
            },
            {
                path: '/calculation_results',
                component: lazy(()=>import('@/pages/CalculationResult')),
                exact: false
            },
            {
                //path: '/calculation_results_show_normal_version/:id',
                path: '/calculation_results_show_normal_version',
                component: lazy(()=>import('@/pages/CalculationResult/ShowNormalVersion')),
                exact: false
            },
            {
                path: '/calculation_results_show_professional_version',
                component: lazy(()=>import('@/pages/CalculationResult/ShowProfessionalVersion')),
                exact: false
            },
            {
                path: '/edit_calculation_result_detail/:id',
                component: lazy(()=>import('@/pages/CalculationResultDetail/edit')),
                exact: false
            },
            {
                path: '/calculation_templates/edit/:id',
                component: lazy(()=>import('@/pages/CalculationTemplate/edit')),
                exact: false
            },
            {
                path: '/calculation_templates/new',
                component: lazy(()=>import('@/pages/CalculationTemplate/new')),
                exact: false
            },
            {
                path: '/calculation_templates',
                component: lazy(()=>import('@/pages/CalculationTemplate')),
                exact: false
            },
            {
                path: '/history_login_logs',
                component: lazy(()=>import('@/pages/HistoryLoginLog')),
                exact: false
            },
            {
                path: '/show_result_as_map/:id',
                component: lazy(()=>import('@/pages/ShowResultAsMap')),
                exact: false
            },
            {
                path: '/dataset/show_result_as_map/:id',
                component: lazy(()=>import('@/pages/Dataset/ShowResultAsMap')),
                exact: false
            },
            {
                path: '/datasets/:id',
                component: lazy(()=>import('@/pages/Dataset/Show')),
                exact: false
            },
            {
                path: '/datasets',
                component: lazy(()=>import('@/pages/Dataset')),
                exact: false
            },
            {
                path: '/add_dataset',
                component: lazy(()=>import('@/pages/Dataset/add')),
                exact: false
            },
            {
                path: '/edit_datasets/:id',
                component: lazy(()=>import('@/pages/Dataset/edit')),
                exact: false
            },
            {
                path: '/materials',
                component: lazy(()=>import('@/pages/Material')),
                exact: false
            },
            {
                path: '/add_materials',
                component: lazy(()=>import('@/pages/Material/add')),
                exact: false
            },
            {
                path: '/edit_materials/:id',
                component: lazy(()=>import('@/pages/Material/edit')),
                exact: false
            },
            {
                path: '/pre_calculation',
                component: lazy(()=>import('@/pages/PreCalculation'))
            },
            {
                path: '/pre_calculation/:id',
                component: lazy(()=>import('@/pages/PreCalculation'))
            },
            {
                path: '/calculation',
                component: lazy(()=>import('@/pages/Calculation'))
            },
            {
                path: '/calculation_for_refrigeration_industrial_and_commercial',
                component: lazy(()=>import('@/pages/CalculatorToolForRefrigerationIndustrialAndCommercial'))
            },
            {
                path: '/calculation_ruan_pao',
                component: lazy(()=>import('@/pages/CalculationRuanPao'))
            },
            {
                path: '/calculation_halon1211',
                component: lazy(()=>import('@/pages/CalculationHalon1211'))
            },
            {
                path: '/calculation_room_air',
                component: lazy(()=>import('@/pages/CalculationRoomAir'))
            },
            {
                path: '/calculation_car_air',
                component: lazy(()=>import('@/pages/CalculationCarAir'))
            },
            {
                path: '/calculation_commerical_hfc_foam',
                component: lazy(()=>import('@/pages/CalculationCommericalHfcFoam'))
            },
            {
                path: '/calculation_instantaneous_emission_of_refrigeration',
                component: lazy(()=>import('@/pages/CalculationInstantaneousEmissionOfRefrigeration'))
            },
            {
                path: '/calculation_production',
                component: lazy(()=>import('@/pages/CalculationProduction'))
            },
            {
                path: '/calculation_foam',
                component: lazy(()=>import('@/pages/CalculationFoam'))
            },
            {
                path: '/calculation/:id',
                component: lazy(()=>import('@/pages/Calculation'))
            },
            {
                path: '/users',
                component: lazy(()=>import('@/pages/User')),
                exact: false
            },
            {
                path: '/new_users',
                component: lazy(()=>import('@/pages/User/new')),
                exact: false
            },
            {
                path: '/forget_password',
                component: lazy(()=>import('@/pages/User/forget')),
                exact: false
            },
            {
                path: '/edit_users/:id',
                component: lazy(()=>import('@/pages/User/edit')),
                exact: false
            },
            {
                path: '/calculation_charts',
                component: lazy(()=>import('@/pages/CalculationCharts')),
                exact: false
            },
            {
                path: '/edit_password',
                component: lazy(()=>import('@/pages/EditPassword')),
                exact: false
            },
            {
                path: '/grid_map',
                component: lazy(()=>import('@/compontens/MyMapToShowResult/gridMap.jsx')),
                exact: false
            },
            {
                path: '/excel_demo',
                component: lazy(()=>import('@/pages/ExcelDemo')),
                exact: false
            },
            {
                path: '*',
                component: lazy(()=>import('@/compontens/MyNotFound')),
                meta: {
                    title: '-'
                }
            },
        ]
    },
];

export default router;
