/**
 * id: 菜单主键id children为[]时，id与path需保持一致
 * name: 菜单名称
 * path: 路由跳转路径
 * children: 子菜单
 */
export const menuJson = [
  {
    id: "/calculation_plans",
    name: "计算方案列表",
    path: "/CalculationPlan",
    children: []
  },
  /**
  {
    id: "/calculation_results",
    name: "所有计算方案的所有结果",
    path: "/CalculationResult",
    children: []
  },
  **/
  {
    id: "/calculation_charts",
    name: "报表页",
    path: "/CalculationCharts",
    children: []
  },
]
export const tabMenuJson = [
  {
    id: "/add_materials",
    name: "新建物质",
    path: "/Materials",
    children: [],
  },
  {
    id: "/new_dataset",
    name: "新建表",
    path: "/Dataset",
    children: [],
  },
  {
    id: "/new_users",
    name: "新建用户",
    path: "/Users",
    children: [],
  },
  {
    id: "/pre_calculation/:id",
    name: "编辑预计算页面",
    path: "/PreCalculation",
    children: [],
  },
  {
    id: "/pre_calculation",
    name: "预计算页面",
    path: "/PreCalculation",
    children: [],
  },
  {
    id: "/edit_dataset/:id",
    name: "编辑表",
    path: "/Dataset",
    children: [],
  },
  {
    id: "/edit_users/:id",
    name: "编辑用户",
    path: "/Users",
    children: [],
  },
  {
    id: "/users",
    name: "用户列表",
    path: "/Users",
    children: [],
  },
  {
    id: "/materials",
    name: "物质列表",
    path: "/Materials",
    children: [],
  },

  {
    id: "/edit_materials/:id",
    name: "编辑物质",
    path: "/Materials/edit",
    children: [],
  },
  {
    id: "/calculation_templates/new",
    name: "新建计算模板",
    path: "/CalculationTemplates",
    children: [],
  },
  {
    id: "/calculation_templates/edit/:id",
    name: "编辑计算模板",
    path: "/CalculationTemplates/edit",
    children: [],
  },

  {
    id: "/history_login_logs",
    name: "用户历史登录",
    path: "/HistoryLoginLogs",
    children: [],
  },
  {
    id: "/calculation_templates",
    name: "计算模板列表",
    path: "/CalculationTemplates",
    children: [],
  },
  {
    id: "/datasets",
    name: "各种表数据",
    path: "/Dataset",
    children: []
  },
  {
    id: "/calculation_plans",
    name: "计算方案列表",
    path: "/CalculationPlan",
    children: []
  },
  {
    id: "/calculation_results_show_normal_version",
    name: "计算方案结果",
    path: "/CalculationResult",
    query: {
      calculation_plan_id: ":id"
    },
    children: []
  },
  /**
  {
    id: "/calculation_results",
    name: "所有计算方案的所有结果",
    path: "/CalculationResult",
    children: []
  },
  **/
  {
    id: "/calculation_charts",
    name: "报表页",
    path: "/CalculationCharts",
    children: []
  },
]

export const adminMenuJson = [
  {
    id: "/calculation_plans",
    name: "计算方案列表",
    path: "/CalculationPlan",
    children: []
  },
  /**
  {
    id: "/calculation_results",
    name: "所有计算结果",
    path: "/CalculationResult",
    children: []
  },
  **/
  {
    id: "/calculation_templates",
    name: "计算模版列表",
    path: "/CalculationTemplate",
    children: []
  },
  {
    id: "/history_login_logs",
    name: "用户历史登陆",
    path: "/HistoryLoginLog",
    children: []
  },
  {
    id: "/materials",
    name: "物质列表",
    path: "/Material",
    children: []
  },
  {
    id: "/users",
    name: "用户列表",
    path: "/User",
    children: []
  },
  {
    id: "/datasets",
    name: "各种表数据",
    path: "/Dataset",
    children: []
  },
  {
    id: "/calculation_charts",
    name: "报表页",
    path: "/CalculationCharts",
    children: []
  },
]

// 返回菜单第一层级id，用于antd中展开项
export const getMenuFirstLevelId = () => {
  let arr = []
  tabMenuJson.forEach(item=>{
      arr.push(item.id)
  })
  return arr
}
// 递归获取第一项
const OpenFisrt = initOpenFisrt()

// 默认展开项
export const initOpenKeys = OpenFisrt.OpenKeys

// 默认选中项
export const initSelectedKeys = OpenFisrt.SelectedKeys

// 默认显示面包屑
export const initBreadcrumb = OpenFisrt.Breadcrumb

// 默认显示选项卡
//export const initTabs = OpenFisrt.Tabs
//export const initTabs = OpenFisrt.Tabs.filter(tab => tab.title)
//export const initTabs = OpenFisrt.Tabs.filter(tab => tab.title || tab.defaultVisible)
export const initTabs = OpenFisrt.Tabs.filter(tab => tab.title && tab.title.trim().length > 0 && tab.title !== '-')

// 登录进入首页或路由地址为'/',默认打开菜单第一项
function initOpenFisrt() {
  let OpenKeys = [],
      SelectedKeys = [],
      Breadcrumb = []
  recursionGetFisrt(tabMenuJson[0], OpenKeys, SelectedKeys, Breadcrumb)
  return {
    OpenKeys,
    SelectedKeys,
    Breadcrumb,
    Tabs: [{
      title: Breadcrumb[Breadcrumb.length-1],
      key: SelectedKeys[0],
      closable: false,
      openKeys: OpenKeys,
      selectedKeys: SelectedKeys,
      breadcrumb: Breadcrumb
    }]
  }
}
function recursionGetFisrt (data, OpenKeys, SelectedKeys, Breadcrumb) {
    let children = data.children
    Breadcrumb.push(data.name)
    if(children && children.length && children.length > 0){
      OpenKeys.push(data.id)
      recursionGetFisrt(children[0], OpenKeys, SelectedKeys, Breadcrumb)
    }else{
      SelectedKeys.push(data.id)
    }
}


// 用于监听浏览器前进后退，根据id获取当前展开项
export const getOpenKeysForId = (id) => {
  return recursionData(tabMenuJson, id).arr
}
// 递归数据
const recursionData = (data, id) => {
  let arr = [], isFind = false
  for(var i in data){
    let cld = data[i].children
    arr = []
    if(cld.length && cld.length > 0){
      arr.push(data[i].id)
      let o = recursionData(cld, id)
      arr = [...arr, ...o.arr]
      if(o.isFind) return {isFind: o.isFind, arr}
    }else{
      if(data[i].path == id){
        isFind = true
        break
      }
    }
  }
  return {isFind, arr}
}

// 更新面包屑显示文字
export const getKeyPathTitle = (keyPath) => {
  let title = []
  keyPath.forEach(item => {
    let str = returnName(tabMenuJson, item)
    if (str) {
      title.push(str)
    }
  })
  return title
}

// 递归查询每一层级的name值
const returnName = (data, value)=>{
  let name = ''
  for(var i in data){
      // 对于普通的路由
      if(data[i].id == value){
          name = data[i].name
      }

      // 对于 路由中包含 :id 的情况
      if(!name && (data[i].id.match(":id")) ) {
        let temp_string = data[i].id.replace(":id", "\\d+")

        let my_regexp = new RegExp(temp_string)
        let temp_string_for_id = value.split('/')
        let id = temp_string_for_id[temp_string_for_id.length -1]
        if( value.match(my_regexp) ) {
          name = data[i].name + '-' + id
        }

      }

    if ( name == "计算方案结果" && data[i].id.includes("calculation_results_show_normal_version")) {
      let temp_string = data[i].id.replace("?calculation_plan_id=", "\\d+");
      let my_regexp = new RegExp(temp_string);
      let temp_string_for_id = value.split("/");
      let id = temp_string_for_id[temp_string_for_id.length - 1];
      let queryString = new URLSearchParams(window.location.search);
      let calculationPlanId = queryString.get("calculation_plan_id").split('?')[0].trim();
      name = data[i].name + "-" + calculationPlanId;
    }
    if(!name && data[i].children.length > 0){
      name = returnName(data[i].children, value)
    }
    if(name) {
      break
    }
  }
  return name
}
